<template>
  <div>
    <div 
      id="data-list-list-view" 
      class="data-list-container">
      <custom-table
        ref="table"
        :max-items="itemsPerPage"
        :data="items"
        :searchKeys="searchKeys"
        pagination
        search
      >
        <div 
          slot="header" 
          class="flex items-center flex-grow justify-between">
          <div
            class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
            @click="addNewContent"
          >
            <feather-icon 
              icon="PlusIcon" 
              svg-classes="h-4 w-4" />
            <span class="ml-2 text-base text-primary">Add New Content</span>
          </div>

          <!-- ITEMS PER PAGE -->
          <LhDropdownPerPage 
            :items="items" 
            :items-per-page="itemsPerPage" 
            :current-page="currentPage" 
            :queried-items="queriedItems"
            @click="(page) => itemsPerPage = page"
          /> 
        </div>

        <template slot="thead">
          <!-- <vs-th sort-key="source">Source Site</vs-th> -->
          <vs-th sort-key="source">Email</vs-th>
          <vs-th sort-key="updated_at">Updated At</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr 
              v-for="(tr, indextr) in data" 
              :data="tr" 
              :key="indextr">
              <td class="td vs-table--td" @click.stop="editData(tr.id)">
                <p>{{ tr.email }}</p>
              </td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">
                <p class="brand-priority url">{{ updatedAtDatetimeFormat(tr.updated_at) }}</p>
              </td>
              <vs-td class="whitespace-no-wrap cursor-default">
                <div class="flex space-x-2">
                  <!-- <feather-icon
                    icon="CopyIcon"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                    @click.stop="duplicateContent(tr.id)"
                  />-->
                  <router-link
                    :to="{ name: `${routePrefix}-edit`, params: { id: tr.id }}"
                    class="pt-2 color-text-link"
                  >
                    <feather-icon
                      icon="EditIcon"
                      svg-classes="w-5 h-5 hover:text-primary stroke-current"
                    />
                  </router-link>
                  <!-- <feather-icon
                    icon="MonitorIcon"
                    svg-classes="w-5 h-5 hover:text-danger stroke-current"
                    @click.stop="checkFollowRedirects(tr)"
                  /> -->
                  <feather-icon
                    class="cursor-pointer"
                    icon="TrashIcon"
                    svg-classes="w-5 h-5 hover:text-danger stroke-current"
                    @click.stop="deleteData(tr.id)"
                  />
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import useCrud from '@/use/useCrud'
import CustomTable from '@/components/CustomTable'
import LhDropdownPerPage from "@/components/LhDropdownPerPage"
import useDatepicker from "@/use/useDatepicker"

export default {
  name: 'SEOListView',
  components: {
    CustomTable,
    LhDropdownPerPage
  },
  data() {
    return {}
  },
  setup(props, ctx) {
    const crudFunction = useCrud(ctx, 'blacklistEmail')
    const { updatedAtDatetimeFormat } = useDatepicker()


    crudFunction.routePrefix.value = 'blacklistEmail'

    const items = computed(() => {
      return crudFunction.result.value
    })

    const addNewContent = () => {
      ctx.root.$router.push({
        name: 'blacklistEmail-create',
      })
    }

    return {
      ...crudFunction,
      items,
      addNewContent,
      updatedAtDatetimeFormat,
      searchKeys: ['email']
    }
  },
}
</script>

<style lang="scss">
  .disabled-btn {
    cursor: default;
    pointer-events: none;
  }
  .url {
    word-break: break-all;
  }
</style>
